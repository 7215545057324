import * as React from "react";

import { cn } from "~/lib/utils";
import { useFormItemContext } from "./form";

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(({ className, ...props }, ref) => {
  const { inputId, descriptionId, errorId, errors } = useFormItemContext();

  // we overwrite the id prop because conform sets it's own id to the input, which messes up everything.
  return (
    <textarea
      className={cn(
        "flex min-h-[80px] w-full rounded-md border border-slate-200 bg-transparent bg-white px-3 py-2 ring-offset-white placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-400 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:ring-offset-slate-950 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-800",
        className,
      )}
      ref={ref}
      aria-describedby={descriptionId}
      aria-invalid={Boolean(errors?.length)}
      aria-errormessage={errorId}
      {...props}
      id={inputId}
    />
  );
});
Textarea.displayName = "Textarea";

export { Textarea };
